// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-template-jsx": () => import("C:\\projects\\3_Podcast\\diehobbyisten-page\\src\\templates\\page-template.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("C:\\projects\\3_Podcast\\diehobbyisten-page\\src\\templates\\post-template.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-tag-template-jsx": () => import("C:\\projects\\3_Podcast\\diehobbyisten-page\\src\\templates\\tag-template.jsx" /* webpackChunkName: "component---src-templates-tag-template-jsx" */),
  "component---src-templates-category-template-jsx": () => import("C:\\projects\\3_Podcast\\diehobbyisten-page\\src\\templates\\category-template.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-pages-404-jsx": () => import("C:\\projects\\3_Podcast\\diehobbyisten-page\\src\\pages\\404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-categories-jsx": () => import("C:\\projects\\3_Podcast\\diehobbyisten-page\\src\\pages\\categories.jsx" /* webpackChunkName: "component---src-pages-categories-jsx" */),
  "component---src-pages-contact-thanks-jsx": () => import("C:\\projects\\3_Podcast\\diehobbyisten-page\\src\\pages\\contact-thanks.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-jsx" */),
  "component---src-pages-contact-jsx": () => import("C:\\projects\\3_Podcast\\diehobbyisten-page\\src\\pages\\contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("C:\\projects\\3_Podcast\\diehobbyisten-page\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-tags-jsx": () => import("C:\\projects\\3_Podcast\\diehobbyisten-page\\src\\pages\\tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\projects\\3_Podcast\\diehobbyisten-page\\.cache\\data.json")

